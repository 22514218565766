"use strict";

import api from "@/api/api";
import userService from "../../services/user.service";
import { CONSTANTS } from "../../global/index";
const {
  SET_TOKEN,
  SET_IDENTITY,
  DOWNLOADING,
  DOWNLOAD_SUCCESS,
  ERROR_MSG,
  ERROR_STATUS,
} = CONSTANTS;
const ERROR_OBJ = {
  status: ERROR_STATUS,
  message: ERROR_MSG,
  result: "",
};

export default {
  namespaced: true,
  state: {
    token: "",
    identity: {},
    dText: "Descargar Reporte.CSV",
    dflag: false,
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getIdentity(state) {
      return state.identity;
    },
    downloadFlag(state) {
      return state.dflag;
    },
    downloadText(state) {
      return state.dText;
    },
  },
  mutations: {
    [SET_TOKEN](state, payload) {
      state.token = payload;
    },
    [SET_IDENTITY](state, payload) {
      state.identity = payload;
    },
    [DOWNLOADING](state, change) {
      state.dflag = false;
      state.dText = change;
    },
    [DOWNLOAD_SUCCESS](state, change) {
      state.dflag = true;
      state.dText = change;
    },
  },
  actions: {
    async login({ commit }, payload) {
      try {
        const headers = { "Content-Type": "application/json" };
        const response = await api.login(payload, headers);
        
        localStorage.removeItem("tkn");
        localStorage.removeItem("lastv");
        localStorage.removeItem("ath");

        if (response.data.status == "success") {
          commit(SET_TOKEN, response.data.result);
          localStorage.setItem("tkn", response.data.result);
        }

        return response.data;
      } catch (error) {
        console.log(error)
        return ERROR_OBJ;
      }
    },

    async getData({ commit }) {
      try {
        const response = await userService.getData();
        if (response.status === "success") {
          commit(SET_IDENTITY, response.result);
        }

        return response;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async resendCode({ commit }, payload) {
      try {
        const headers = { "Content-Type": "application/json" };
        const response = await api.resendCode(payload, headers);

        return response;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async contact({ commit }, payload) {
      try {
        const headers = { "Content-Type": "application/json" };
        const response = await api.addItem(payload, headers);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    logout({ commit }) {
      localStorage.removeItem("tkn");
      localStorage.removeItem("lastv");
      localStorage.removeItem("ath");
      commit(SET_IDENTITY, null);
      commit(SET_TOKEN, null);
    },
  },
};
