"use strict";

import api from "@/api/api";
import userService from "../../services/user.service";
import { CONSTANTS } from "../../global/index";
const {
  GETTING_INFO,
  GET_INFO_SUCCESS,
  SET_HISTORY_OPTION,
  SET_ADDED,
  SET_STATUS,
  ERROR_MSG,
  ERROR_STATUS,
} = CONSTANTS;

const ERROR_OBJ = {
  status: ERROR_STATUS,
  message: ERROR_MSG,
  result: "",
};

export default {
  namespaced: true,
  state: {
    loading: false,
    data: [],
    historyOption: "Default",
    status: "",
    added: "",
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    data(state) {
      return state.data;
    },
    getHistoryOption(state) {
      return state.historyOption;
    },
    getAdded(state) {
      return state.added;
    },
    status(state) {
      return state.status;
    },
  },
  mutations: {
    [GETTING_INFO](state) {
      state.loading = true;
      state.data = [];
    },
    [GET_INFO_SUCCESS](state, change) {
      state.loading = false;
      state.data = change;
    },
    [SET_HISTORY_OPTION](state, change) {
      state.historyOption = change;
    },
    [SET_ADDED](state, change) {
      state.added = change;
    },
    [SET_STATUS](state, value) {
      state.status = value;
    },
  },
  actions: {
    setAddedInr({ commit }, payload) {
      commit(SET_ADDED, payload);
    },

    setHistoryOptionInr({ commit }, payload) {
      commit(SET_HISTORY_OPTION, payload);
    },

    async getAllInfoInr({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.getAllInfo(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async getInfoByIdInr({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.getInfoById(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data.result;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async deleteItemInr({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.deleteItem(payload, headers);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async editItemInr({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.editItem(payload, headers);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async addItemInr({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.addItem(payload, headers);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },
  },
};
