"use strict";

import api from "@/api/api";
import userService from "../../services/user.service";
import { CONSTANTS } from "../../global/index";
const {
  GETTING_INFO,
  GET_INFO_SUCCESS,
  GETTING_IMG,
  GET_IMG_SUCCESS,
  SET_HISTORY_OPTION,
  SET_ADDED,
  SET_STATUS,
  SET_STATUSIMG,
  ERROR_MSG,
  ERROR_STATUS,
  SET_CURRENT_SERVICE,
} = CONSTANTS;

const ERROR_OBJ = {
  status: ERROR_STATUS,
  message: ERROR_MSG,
  result: "",
};

export default {
  namespaced: true,
  state: {
    loading: false,
    data: [],
    loading_img: false,
    img: null,
    historyOption: "Default",
    currentService: 1,
    status: "",
    statusimg: "error",
    added: "",
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    data(state) {
      return state.data;
    },
    loading_img(state) {
      return state.loading_img;
    },
    img(state) {
      return state.img;
    },
    getHistoryOption(state) {
      return state.historyOption;
    },
    getCurrentService(state) {
      return state.currentService;
    },
    getAdded(state) {
      return state.added;
    },
    status(state) {
      return state.status;
    },
    statusimg(state) {
      return state.statusimg;
    },
  },
  mutations: {
    [GETTING_INFO](state) {
      state.loading = true;
      state.data = [];
    },
    [GET_INFO_SUCCESS](state, change) {
      state.loading = false;
      state.data = change;
    },
    [GETTING_IMG](state) {
      state.loading_img = true;
      state.img = null;
    },
    [GET_IMG_SUCCESS](state, change) {
      state.loading_img = false;
      state.img = change;
    },
    [SET_HISTORY_OPTION](state, change) {
      state.historyOption = change;
    },
    [SET_CURRENT_SERVICE](state, change) {
      state.currentService = change;
    },
    [SET_ADDED](state, change) {
      state.added = change;
    },
    [SET_STATUS](state, value) {
      state.status = value;
    },
    [SET_STATUSIMG](state, value) {
      state.statusimg = value;
    },
  },
  actions: {
    setAddedMain({ commit }, payload) {
      commit(SET_ADDED, payload);
    },

    setHistoryOptionMain({ commit }, payload) {
      commit(SET_HISTORY_OPTION, payload);
    },
    setCurrentServiceMain({ commit }, payload) {
      commit(SET_CURRENT_SERVICE, payload);
    },
    async getAllInfo({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.getAllInfo(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async getInfoById({ commit }, payload) {
      commit(GETTING_INFO);
      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.getInfoById(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data.result;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async getImage({ commit }, payload) {
      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.getInfoById(payload, headers);
        commit(GET_IMG_SUCCESS, response.data.result.image);
        const payloadStatus = {
          option: payload.option,
          image: response.data.result.image,
        };
        const responseStatus = await api.getStatusImage(payloadStatus, headers);
        if (responseStatus.data.status !== "error") {
          commit(SET_STATUSIMG, "success");
        }

        return response.data.result;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async getStatusImage({ commit }, payload) {
      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.getStatusImage(payload, headers);

        return response;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async search({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.search(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data.result;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async deleteItem({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");
      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.deleteItem(payload, headers);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },
    async editItem({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");
      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.editItem(payload, headers);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async editImage({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");
      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.editImage(payload, headers);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async addImage({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");
      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.addImage(payload, headers);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async addItem({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const headers = { Authorization: token };
        const response = await api.addItem(payload, headers);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },
  },
};
