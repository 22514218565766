"use strict"

import api from "@/api/api"
import userService from "../../services/user.service"

const DEFAULT_ERROR = {
    status: "error",
    message: "Ha ocurrido un error al intentar guardar la información.",
    result: "",
  }

const SET_ADDED = "SET_ADDED",
  SET_CTK = "SET_CTK",
  SET_CAR = "SET_CAR"

export default {
  namespaced: true,
  state: {
    _ctk: "",
    car: "",
    added: "",
  },
  getters: {
    getAdded(state) {
      return state.added
    },

    getcar(state) {
      return state.car
    },
    gettkn(state) {
      return state._ctk
    },
  },
  mutations: {
    [SET_ADDED](state, change) {
      state.added = change
    },

    [SET_CAR](state, change) {
      state.car = change
    },
    [SET_CTK](state, change) {
      state._ctk = change
    },
  },
  actions: {
    //Asignar el objeto de carrito
    setCarrito({ commit }, payload) {
      commit(SET_CAR, payload)
    },

    setToken({ commit }, payload) {
      commit(SET_CTK, payload)
    },

    setAddedCrt({ commit }, payload) {
      commit(SET_ADDED, payload)
    },

    async setCart({ commit }, payload) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.addItem(payload, headers)

        if (response.data.status == "success") {
          commit(SET_CAR, response.data.dec)
          commit(SET_CTK, response.data.result)
          localStorage.setItem("_ctk", response.data.result)
        } else {
          commit(SET_CAR, "")
          commit(SET_CTK, "")
        }

        return response.data
      } catch (error) {
        return DEFAULT_ERROR
      }
    },

    async getCart({ commit }) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }

        const _ctk = localStorage.getItem("_ctk")
        const payload = {
          option: "get_cart",
          item: { _ctk: _ctk },
        }
        const response = await api.addItem(payload, headers)

        if (response.data.status == "success") {
          commit(SET_CAR, response.data.result)
          commit(SET_CTK, _ctk)
        } else {
          commit(SET_CAR, "")
          commit(SET_CTK, "")
        }

        return response.data
      } catch (error) {
        return DEFAULT_ERROR
      }
    },

    async deleteItemCrt({ commit }, payload) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.deleteItem(payload, headers)
        if (response.data.status == "success") {
          commit(SET_CTK, response.data.result)
          localStorage.setItem("_ctk", response.data.result)
        }

        return response.data
      } catch (error) {
        return DEFAULT_ERROR
      }
    },

    async editItemCrt({ commit }, payload) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.editItem(payload, headers)

        if (response.data.status == "success") {
          commit(SET_CTK, response.data.result)
          localStorage.setItem("_ctk", response.data.result)
        }

        return response.data
      } catch (error) {
        return DEFAULT_ERROR
      }
    },

    async addItemCrt({ commit }, payload) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.addItem(payload, headers)

        if (response.data.status == "success") {
          commit(SET_CTK, response.data.result)
          localStorage.setItem("_ctk", response.data.result)
        }

        return response.data
      } catch (error) {
        return DEFAULT_ERROR
      }
    },

    async validateCrt({ commit }, payload) {
      try {
        const token = userService.getToken()
        const headers = { Authorization: token }
        const response = await api.addItem(payload, headers)

        return response.data
      } catch (error) {
        return DEFAULT_ERROR
      }
    },
  },
}
