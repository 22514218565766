"use strict";

import api from "@/api/api";
import userService from "../../services/user.service";

import { CONSTANTS } from "../../global/index"
const { GETTING_INFO, 
    GET_INFO_SUCCESS, 
    SET_HISTORY_OPTION, 
    SET_ADDED,SET_STATUS, 
    ERROR_MSG,
    ERROR_STATUS
 } = CONSTANTS;
 const ERROR_OBJ = {
  status: ERROR_STATUS,
  message: ERROR_MSG,
  result: "",
}
export default {
  namespaced: true,
  state: {
    loading: false,
    data: [],
    historyOption: "Default",
    status: "",
    added: "",
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    data(state) {
      return state.data;
    },
   
    getHistoryOption(state) {
      return state.historyOption;
    },
  
    getAdded(state) {
      return state.added;
    },
    status(state) {
      return state.status;
    },
  
  },
  mutations: {
    [GETTING_INFO](state) {
      state.loading = true;
      state.data = [];
    },
    [GET_INFO_SUCCESS](state, change) {
      state.loading = false;
      state.data = change;
    },
    [SET_HISTORY_OPTION](state, change) {
      state.historyOption = change;
    },
    [SET_ADDED](state, change) {
      state.added = change;
    },
    [SET_STATUS](state, value) {
      state.status = value;
    },
  },
  actions: {
    setAddedPln({ commit }, payload) {
      commit(SET_ADDED, payload);
    },

    setHistoryOptionPln({ commit }, payload) {
      commit(SET_HISTORY_OPTION, payload);
    },
    
    async getAllInfoPln({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        
        const headers = { Authorization: token };
        const response = await api.getAllInfo(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async getInfoByIdPln({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        
        const headers = { Authorization: token };
        const response = await api.getInfoById(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data.result;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async getByDatePln({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        
        const headers = { Authorization: token };
        const response = await api.getByDate(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data.result;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async deleteItemPln({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        
        const headers = { Authorization: token };
        const response = await api.deleteItem(payload, headers);
        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },
    async editItemPln({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        
        const headers = { Authorization: token };
        const response = await api.editItem(payload, headers);
        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async addItemPln({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        
        const headers = { Authorization: token };
        const response = await api.addItem(payload, headers);
        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    }
  },
};
