<template>
    <section v-if="carrito.items">
        <button @click="openCarrito = true">
            <p class="badge">{{ carrito.items.length }}</p>
            <img src="../../assets/svgs/cart.svg" alt="carrito">
        </button>
        <div v-if="openCarrito === true" @click="openCarrito = false" class="cartPopBG"></div>
        <div :class="{ 'openCarrito': openCarrito === true }" class="cartPop flx">

            <div class="contbtns flx">

                <h2>CARRITO DE <br> COMPRA</h2>
                <img @click="openCarrito = false" class="closeBtn" src="../../assets/svgs/Close.svg" alt="">

            </div>

            <div v-if="carritoStatus" class="notification">
                <p>{{ carritoStatus }}</p>
            </div>

            <div class="productList">
                <template v-for="(carritoItem, index) in carrito.items">
                    <hr>
                    <div class="carritoItem flx">
                        
                        <img v-if="carritoItem?.item?.image !== ''" :src="srcImg(carritoItem.item.image)" alt="">
                        <img v-else src="../../assets/productTemplate.png" alt="">


                        <div class="carritoItemInfo flx">

                            <div class="carritoItemInfoMain flx">
                                <p>{{ carritoItem.item.name }} </p>
                                <p> ${{ carritoItem.subtotal }}</p>
                            </div>
                            <span>
                                {{ carritoItem.item.descrp }}
                            </span>
                            <div class="carritoItemInfoOptions flx">
                                <div v-if="validateAttribute(carritoItem)" class="SingleInfo flx">
                                    <span>{{ carritoItem.item?.attribute.attribute_name }}</span>
                                </div>


                                <div class="SingleInfo flx">
                                    <p>cantidad:</p> <button @click="lessCant(carritoItem, index)"
                                        :disabled="carritoItem.cantidad === 1">-</button> <span><strong>X</strong>{{
                                            carritoItem.cantidad }}</span><button @click="moreCant(carritoItem, index)"
                                        :disabled="carritoItem.item.manage_inventory === 1 && carritoItem.item.quantity <= carritoItem.cantidad">+</button>
                                </div>

                                <button class="delCarritoItem" @click="deleteItem(index)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24"
                                        fill="none">
                                        <path
                                            d="M17.4975 6.0196V21.3674C17.4975 22.0913 17.183 22.7492 16.684 23.2275C16.18 23.7059 15.4868 24 14.7241 24H4.27626C3.51355 24 2.82002 23.7059 2.31637 23.2275C1.81695 22.7492 1.50283 22.0913 1.50283 21.3674V6.0196C1.50283 5.77406 1.7153 5.57206 1.97436 5.57206H17.026C17.2847 5.57206 17.4975 5.77406 17.4975 6.0196ZM16.5495 21.3674V6.46747H2.44554V21.3677C2.44554 21.8461 2.65343 22.2802 2.98625 22.5917C3.31908 22.9076 3.77226 23.1049 4.27591 23.1049H14.7234C15.2224 23.1049 15.6802 22.9076 16.013 22.5917C16.3462 22.2799 16.5495 21.8457 16.5495 21.3674Z"
                                            fill="#13110C" />
                                        <path
                                            d="M18.0566 5.57206V4.62005C18.0566 4.23382 17.89 3.88745 17.6221 3.63286C17.3539 3.37828 16.9886 3.2205 16.5816 3.2205H2.41766C2.01107 3.2205 1.64577 3.37828 1.37754 3.63286C1.10965 3.88745 0.943065 4.23382 0.943065 4.62005V5.57206H18.0566ZM19 4.62005V6.0196C19 6.26983 18.7872 6.46713 18.5281 6.46713H0.471532C0.208237 6.46746 0 6.26983 0 6.0196V4.62005C0 3.98828 0.272825 3.41345 0.707299 2.99673C1.14636 2.58437 1.75201 2.32543 2.41766 2.32543H16.582C17.2476 2.32543 17.8533 2.58437 18.2923 2.99673C18.7272 3.41345 19 3.98828 19 4.62005Z"
                                            fill="#13110C" />
                                        <path
                                            d="M5.10356 9.63504C5.10356 9.38514 5.31603 9.1875 5.57509 9.1875C5.8338 9.1875 6.04663 9.38514 6.04663 9.63504V19.9414C6.04663 20.1869 5.83415 20.3889 5.57509 20.3889C5.31603 20.3889 5.10356 20.1869 5.10356 19.9414V9.63504Z"
                                            fill="#13110C" />
                                        <path
                                            d="M9.02829 9.63504C9.02829 9.38514 9.24112 9.1875 9.49982 9.1875C9.75888 9.1875 9.97135 9.38514 9.97135 9.63504V19.9414C9.97135 20.1869 9.75888 20.3889 9.49982 20.3889C9.24112 20.3889 9.02829 20.1869 9.02829 19.9414V9.63504Z"
                                            fill="#13110C" />
                                        <path
                                            d="M12.953 9.63504C12.953 9.38514 13.1609 9.1875 13.4246 9.1875C13.6833 9.1875 13.8961 9.38514 13.8961 9.63504V19.9414C13.8961 20.1869 13.6833 20.3889 13.4246 20.3889C13.1609 20.3889 12.953 20.1869 12.953 19.9414V9.63504Z"
                                            fill="#13110C" />
                                        <path
                                            d="M7.29922 0.895067C7.21134 0.895067 7.13299 0.952014 7.07263 1.04413C6.96181 1.21531 6.90145 1.47425 6.90145 1.76803V2.3251H12.0975V1.76836C12.0975 1.47425 12.0375 1.21564 11.9263 1.04447C11.8663 0.952349 11.7876 0.895402 11.6997 0.895402L7.29922 0.895067ZM7.29922 0H11.7001C12.1487 0 12.4907 0.219412 12.7264 0.574826C12.9297 0.886358 13.0409 1.31178 13.0409 1.76803V2.77263C13.0409 3.01851 12.8281 3.22017 12.5694 3.22017H6.43027C6.17121 3.22017 5.95874 3.01817 5.95874 2.77263V1.76836C5.95874 1.31212 6.06957 0.886358 6.27286 0.575161C6.50898 0.219412 6.85098 0 7.29922 0Z"
                                            fill="#13110C" />
                                    </svg>
                                </button>
                            </div>

                        </div>
                    </div>
                </template>

            </div>

            <hr class="pc">

            <div class="subTotal flx">
                <p>SUBTOTAL</p>
                <p><strong>${{ carrito.total }}</strong></p>
            </div>
            <div class="contbtns flxEnd flx">

                <button @click="goToPay()" class="inPink">
                    <p>Finalizar compra</p> <img src="../../assets/svgs/Arrow 2.svg" alt="">
                </button>
            </div>

        </div>
    </section>
</template>
<script>
import { mapActions } from 'vuex';
import { url } from '@/global'

export default {
    name: 'carritoPopUp',
    data() {
        return {
            openCarrito: false,
            notificando: false,
            status: "",
            message: "",
            carritoStatus: ""
        }
    },
    created() {
        this.getCart()
    },
    computed: {
        total() {
            let total = 0;
            this.carrito.forEach(producto => {
                total += producto.cantidad * producto.precioUnitario;
            });
            return total;
        },
        carrito() {
            let data = this.$store.getters["carrito/getcar"];
            return data
        },
    },
    methods: {
        ...mapActions('carrito', ['getCart']),
        validateAttribute(carritoItem) {
            if (carritoItem?.item?.attribute?.attribute_name) return true
            return false
        },
        srcImg(link) {
            return `${url}/merch/product-img/${link}`
        },
        lessCant(carritoItem) {
            if (carritoItem.item.limit_purchases === 1) {

                if (carritoItem.item.minimum_quantity < carritoItem.cantidad) {
                    carritoItem.cantidad--
                }
                else {
                    this.carritoState = 'error'
                    this.carritoStatus = 'Alcanzaste la cantidad minima de compra de este producto'
                    this.delStatus()
                    return
                }

            }
            else {
                if (carritoItem.cantidad === 1) {
                    this.carritoState = 'error'
                    this.carritoStatus = 'Alcanzaste la cantidad minima de compra de este producto'
                    this.delStatus()

                    return

                }
                else {
                    carritoItem.cantidad--
                }
            }
            this.actualizarCarrito()
        },
        moreCant(carritoItem) {
            if (carritoItem.item.limit_purchases === 1) {
                if (carritoItem.item.maximum_amount > carritoItem.cantidad) {
                    carritoItem.cantidad++
                }
                else {
                    this.carritoState = 'error'
                    this.carritoStatus = 'Alcanzaste la cantidad maxima de compra de este producto'
                    this.delStatus()
                    return
                }
            }
            else {
                carritoItem.cantidad++
            }

            this.actualizarCarrito()
        },
        async deleteItem(index) {
            const result = await this.$store.dispatch("carrito/deleteItemCrt", {
                option: "del_item",
                item: {
                    index, "_ctk": localStorage.getItem('_ctk')
                },
            });
            if (result.status == "error") {
                this.carritoState = 'error'
                this.carritoStatus = result.message
                alert(result.message)
            } else {
                await this.getCart()
            }
        },
        async actualizarCarrito() {
            const result = await this.$store.dispatch("carrito/editItemCrt", {
                option: "upt_cart",
                item: {
                    "carrito": this.carrito
                },
            });
            console.log(result)
            if (result.status == "error") {
                this.carritoState = 'error'
                this.carritoStatus = result.message
                alert(result.message)
            } else {
                await this.getCart()
            }
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
                , 2000);
        },
        delMsgs: function () {
            this.status = ""
            this.message = ""
            this.carritoState = ""
            this.carritoStatus = ""
        },
        goToPay() {
            this.$router.push("/pago-merch").catch((err) => { });
        }
    }

}

</script>
<style scoped>
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

section>button {
    padding: 1.1979166666666667vw;
    border: 0.1vw solid #E1D9CD;
    background-color: #221D1A;
    border-radius: 10vw;

    width: 4.375vw;
    height: 4.375vw;

    bottom: 2vw;
    right: 2vw;

    position: fixed;
    cursor: pointer;
    z-index: 888;
}

section>button img {
    width: 1.9791666666666665vw;
    height: 1.9791666666666665vw;
    margin-left: -0.1vw;
}

.badge {
    aspect-ratio: 1;
    width: 1.5vw;
    background-color: #A1D469;
    border-radius: 10vw;
    position: absolute;
    left: -0.2vw;
    top: -0.2vw;
    padding: 0.20833333333333334vw 0.4166666666666667vw;

    color: var(--grayblack, #221D1A);
    font-family: Roboto;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.023437500000000003vw;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

}

.flx {
    display: flex;
}

.closeBtn {
    width: 2.864583333333333vw;
    height: 2.864583333333333vw;
    filter: brightness(0);
    cursor: pointer;
}

.cartPopBG {
    background-color: #221d1a9f;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 888;
}

.cartPop {
    flex-direction: column;
    position: fixed;
    width: 31.197916666666664vw;
    height: 90%;
    z-index: 9999;
    right: 0.5vw;
    bottom: -90vh;
    background-color: #E1D9CD;
    padding: 2.473958333333333vw 2.083333333333333vw;
    border-radius: 1.6vw 1.6vw 0 0;
    gap: 1.40625vw;
    transition: all 500ms ease;

}

.openCarrito {
    bottom: 0;
}

.cartPop h2 {
    color: var(--grayblack, #221D1A);
    font-family: Roboto;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 94%;
    letter-spacing: 0.0625vw;
    text-transform: uppercase;
    flex-grow: 0;
}

.cartPop hr {
    height: 0.05vw;
    background-color: #221D1A;
    border: none;
}

.notification {
    display: flex;
    width: 100%;
    padding: 1.3541666666666667vw 1.3541666666666667vw;
    justify-content: space-between;
    align-items: center;

    background-color: #f14f4f;
    border-radius: 0.8vw;
}

.notification p {
    color: var(--Dark, #282828);
    /* p. titles */
    font-family: Roboto;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 700;
    line-height: 133.188%;
    letter-spacing: 0.023437500000000003vw;
    text-transform: uppercase;
}

.productList {
    flex-grow: 1;
    overflow-y: scroll;
}

.productList {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.productList::-webkit-scrollbar {
    display: none;
}

.subTotal {
    flex-grow: 0;
    flex-direction: row;
    justify-content: space-between;
}

.subTotal p {
    color: var(--grayblack, #221D1A);
    font-family: Roboto;
    font-size: 1.6666666666666667vw;
    font-style: normal;
    font-weight: 700;
    line-height: 94%;
    letter-spacing: 0.04166666666666667vw;
    text-transform: uppercase;
}

.contbtns {
    flex-grow: 0;
    flex-direction: row;
    justify-content: space-between;

}

.flxEnd {
    justify-content: flex-end
}

.contbtns button {
    height: 2.1875vw;
    display: flex;
    padding: 0.4166666666666667vw 1.6vw;
    border-radius: 5vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.3vw;
    cursor: pointer;
    text-decoration: none;
}

.contbtns button p {
    font-family: Roboto;
    font-size: 0.8333333333333334vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.020833333333333336vw;
    text-transform: uppercase;
}

.outBlack {
    background-color: transparent;
    border: #282828 solid 0.1vw;
    color: #221D1A;
}

.inPink {
    background-color: #ED87C0;
    border: #282828 solid 0.1vw;
    color: #221D1A;
}

.inPink img {
    filter: brightness(0);
}

.carritoItem {
    padding: 2.2395833333333335vw 0;
    flex-direction: row;
    gap: 1.1979166666666667vw;
}

.carritoItem>img {
    width: 5.052083333333333vw;
    height: 5.46875vw;
    border-radius: 10vw 10vw 0 0;
}

.carritoItemInfo {
    flex-direction: column;
    gap: 0.4166666666666667vw;
    width: 100%;
}

.carritoItemInfoMain {
    flex-direction: row;
    justify-content: space-between;
}

.carritoItemInfoMain p {
    color: var(--Dark, #282828);
    font-family: Roboto;
    font-size: 1.0416666666666665vw;
    font-style: normal;
    font-weight: 700;
    line-height: 133.188%;
    letter-spacing: 0.026041666666666668vw;
    text-transform: uppercase;
    width: 16vw;
    text-wrap: nowrap;
    overflow: clip;
    text-overflow: ellipsis;
}

.carritoItemInfoMain p:last-of-type {
    width: auto !important;

}

.carritoItemInfo>span {
    font-family: "Roboto Mono";
    font-size: 0.5208333333333333vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.carritoItemInfoOptions {
    flex-direction: row;
    gap: 0.6770833333333334vw;
    flex-wrap: wrap;
}

.delCarritoItem {
    margin-inline-start: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 1.7vw;
    width: 1.7vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10vw;
    transition: all 300ms ease;
}

.delCarritoItem svg {
    transition: all 300ms ease;
    width: 0.9895833333333333vw;
    height: 1.25vw;
}

.delCarritoItem:hover {
    background-color: rgb(224, 71, 71);
}

.delCarritoItem:hover svg {
    filter: brightness(1000);
    scale: 0.8;
}

.SingleInfo {
    flex-direction: row;
    align-items: center;
    gap: 0.6166666666666667vw;
}

.SingleInfo p {
    color: var(--Pink, #ED87C0);
    font-family: Roboto;
    font-size: 0.7291666666666666vw;
    font-style: normal;
    font-weight: 700;
    line-height: 133.188%;
    letter-spacing: 0.018229166666666664vw;
    text-transform: uppercase;
}

.SingleInfo span {
    display: flex;
    width: auto;
    padding: 0.2vw 1vw;
    justify-content: center;
    align-items: center;

    border-radius: 5vw;
    border: #221D1A 0.1vw solid;

    color: var(--Dark, #282828);
    text-align: center;
    font-family: Roboto;
    font-size: 0.9375vw;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.023437500000000003vw;
    text-transform: uppercase;
}

.SingleInfo span strong {
    display: none;
}

.SingleInfo button {
    height: 1vw;
    width: 1vw;
    border-radius: 2vw;
    background-color: transparent;
    border: 0.1vw solid #221D1A;
    font-size: 0.7vw;
    font-family: Roboto;
    line-height: 0;

}

.SingleInfo button:disabled {
    opacity: 0.2;
}

.colorSwatch {
    background-color: var(--BGcolor);
    outline: #E1D9CD solid 0.32vw;
    outline-offset: -0.38vw;
}

.pc {
    display: block
}

@media (max-width: 768px) {
    .pc {
        display: none
    }

    section>button {
        padding: 3.1979166666666667vw;
        width: 18.375vw;
        height: 18.375vw;
        right: 4.1vw;
        bottom: 3vw;
    }

    section>button img {
        width: 8.9791666666666665vw;
        height: 8.9791666666666665vw;
        margin-left: -0.3vw;
    }

    .badge {
        font-size: 4vw;
        height: auto;
        width: auto;
        padding: 1vw 2.1vw;
        left: -2vw;
        top: -2vw;
        display: flex;
        align-items: center;
    }

    .cartPop {
        bottom: -100vh;
        left: 50%;
        width: 90vw;
        margin-left: -45vw;
        padding: 9.30232558139535vw 6.976744186046512vw;
        gap: 4.651162790697675vw;
    }

    .notification {
        padding: 2.3255813953488373vw 5.813953488372093vw;
        border-radius: 3vw;
    }

    .notification p {
        font-size: 4.186046511627907vw;
    }

    .cartPop h2 {
        font-size: 9.534883720930234vw;
    }

    .closeBtn {
        width: 10.864583vw;
        height: 10.864583vw;
    }

    .openCarrito {
        bottom: 0vh;
    }

    .carritoItem {
        padding: 4.651162790697675vw 0;
    }

    .carritoItem>img {
        display: none;
    }

    .carritoItemInfoMain {
        align-items: center;
    }

    .colorSwatch {
        outline: #E1D9CD solid 1.32vw;
        outline-offset: -1.4vw;
    }

    .carritoItemInfoMain p {
        font-size: 4.186046511627907vw;
    }

    .carritoItemInfoMain p:first-of-type {
        width: 58vw;
    }

    .carritoItemInfo {
        width: 100%;
        gap: 2.3255813953488373vw;
    }

    .carritoItemInfo>span {
        display: none;
    }

    .cartPop hr {
        height: 0.5vw;
        background-color: #221D1A;
        border: none;
    }

    .SingleInfo {
        gap: 2.616667vw;
    }

    .SingleInfo p {
        display: none;
    }

    .SingleInfo span {
        font-size: 4.186046511627907vw;
        width: auto;
        padding: 1vw 3vw;
        height: 6.511627906976744vw;
    }

    .SingleInfo span strong {
        display: flex;
    }

    .SingleInfo button {
        height: 5vw;
        width: 5vw;
        border-radius: 4vw;
        border: 0.4vw solid #221D1A;
        font-size: 5.7vw;
    }


    .delCarritoItem {
        width: 6.511627906976744vw;
        height: 6.511627906976744vw;
    }

    .carritoItemInfoOptions {
        gap: 1.677083vw;
    }

    .delCarritoItem svg {
        width: 4.5111627906976744vw;
        height: 4.5111627906976744vw;
    }

    .subTotal {
        height: 10vw;
        align-items: center;
    }

    .subTotal p {
        font-size: 4.186046511627907vw;
        line-height: auto;
    }

    .subTotal strong {
        font-size: 8.372093023255815vw;
        line-height: auto;
    }

    .contbtns button {
        padding: 2.416667vw 10.2vw;
        border-radius: 10vw;
        border: none;
        height: auto;
    }

    .contbtns button p {
        font-size: 5.116279069767442vw;
    }


}
</style>